import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { setDefaultBankAccountId } from 'pages/dashboard/components/Configuration/configurationSlice';
import { getCountries, getOnboardingTabCode } from 'pages/dashboard/selectors';
import { Input, Select, RadioGroup, NumberInput } from 'components/Inputs';
import { OnboardingTabCodes } from 'entities/dashboard';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddressState from 'components/AddressState';
import sample from 'assets/images/bank-sample.png';

import { getBankAccountInfoSchema, getBankName } from '../utils';
import { setIsBankAccountOpen } from '../bankAccountsSlice';
import {
  getSelectedIntegrator,
  getBankAccountTypes,
  getBankAccountInfo,
  getBankAccountId,
  getSelectedCountry,
  getIsCreatedByCurrentPerson,
  getTGPAccountReferenceId,
  getBankAccounts,
} from '../selectors';
import {
  createBankAccount,
  updateBankAccount,
  fetchBankAccountData,
} from '../thunks';

const BankAccountInfo = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();
  const selectedIntegrator = useAppSelector(getSelectedIntegrator);
  const bankAccountTypes = useAppSelector(getBankAccountTypes);
  const bankAccountInfo: any = useAppSelector(getBankAccountInfo);
  const bankAccountId: any = useAppSelector(getBankAccountId);
  const bankAccounts: any = useAppSelector(getBankAccounts);
  const onboardingTabCode: OnboardingTabCodes =
    useAppSelector(getOnboardingTabCode);
  const isCreatedByCurrentPerson: boolean = useAppSelector(
    getIsCreatedByCurrentPerson,
  );
  const countries = useAppSelector(getCountries);
  const selectedCountry = useAppSelector(getSelectedCountry);
  const tgpaccountReferenceId = useAppSelector(getTGPAccountReferenceId);

  const isDisabled =
    tgpaccountReferenceId !== null && tgpaccountReferenceId !== '';

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: bankAccountInfo,
    resolver: yupResolver(getBankAccountInfoSchema(t)),
  });

  useEffect(() => {
    dispatch(fetchBankAccountData(selectedIntegrator?.integratorsId));
  }, [dispatch, selectedIntegrator]);

  const validateAccountNumber = (data: any) => {
    const last4 = data.accountNumber.slice(-4);

    const account = bankAccounts.find(
      (bank) =>
        bank.isActive &&
        bank.bankAccountsId !== bankAccountId &&
        bank.accountNumberLast4 === last4 &&
        bank.banksIntegratorsId === selectedIntegrator?.banksIntegratorsId,
    );

    return account ? false : true;
  };

  const onSubmit = async (data: any) => {
    const accountNumberIsValid = validateAccountNumber(data);

    if (accountNumberIsValid) {
      const result = await dispatch(
        bankAccountId ? updateBankAccount(data) : createBankAccount(data),
      );

      if (result.error && result.payload) {
        setError('accountNumber', {
          type: 'manual',
          message: t('bank:fields.accountNumber.validation.unique'),
        });
        return;
      }

      if (
        result.payload &&
        onboardingTabCode === OnboardingTabCodes.configuration
      ) {
        dispatch(setIsBankAccountOpen(false));
        dispatch(setDefaultBankAccountId(result.payload.bankAccountsId));
      }
    } else {
      setError('accountNumber', {
        type: 'manual',
        message: t('bank:fields.accountNumber.validation.unique'),
      });
    }
  };

  const countryISOCode = watch('countryISOCode');

  return (
    <div className="p-6 flex-1 overflow-auto relative">
      {selectedIntegrator && (
        <div className="h-12 flex items-center justify-center mb-2.5">
          <img
            alt={selectedIntegrator.bankName}
            src={`/assets/images/banks/${getBankName(
              selectedIntegrator.bankName,
            )}.png`}
            className="mx-auto max-w-[200px]"
          />
        </div>
      )}

      <p className="heading-2xl text-center mb-8">{t('modalTitle')}</p>

      <form
        id="bankAccountForm"
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-md mx-auto px-1"
      >
        <Input
          name="accountName"
          label={t('bank:fields.bankName.label')}
          className="mb-6 pb-6 border-b"
          register={register}
          error={errors.accountName}
        />
        <Input
          name="addressLine1"
          label={t('common:fields.address.label')}
          className="mb-6"
          register={register}
          error={errors.addressLine1}
        />
        <Select
          name="countryISOCode"
          label={t('common:fields.country.label')}
          className="mb-6"
          options={countries}
          setValue={setValue}
          value={selectedCountry}
          error={errors.countryISOCode}
          register={register}
          withIcon
        />
        <Input
          name="city"
          label={t('common:fields.city.label')}
          className="mb-6"
          register={register}
          error={errors.city}
        />

        <div className="flex pb-6 border-b -mx-4.5">
          <AddressState
            label={t('common:fields.province.label')}
            name="stateOrProvince"
            className="w-1/2 mx-4.5"
            countryISOCode={countryISOCode}
            value={bankAccountInfo.stateOrProvince}
            error={errors?.stateOrProvince}
            register={register}
            setValue={setValue}
          />
          <Input
            name="postalCode"
            label={t('common:fields.postal.label')}
            className="w-1/2 mx-4.5"
            register={register}
            error={errors?.postalCode}
          />
        </div>

        <img src={sample} alt="Sample" className="mt-12 mx-auto mb-6" />

        <RadioGroup
          options={bankAccountTypes}
          checked={bankAccountInfo.bankAccountTypesId}
          label={t('bank:fields.accountType.label')}
          name="bankAccountTypesId"
          className="mb-6"
          optionsClassName="space-x-10"
          register={register}
          error={errors.bankAccountTypesId}
          disabled={isDisabled}
          optionClassName="mr-4"
        />
        <NumberInput
          name="routingNumber"
          label={t('bank:fields.routingNumber.label')}
          className="mb-6"
          control={control}
          error={errors.routingNumber}
          type="int"
          maxLength={9}
          allowNegative={false}
          hideValue={!isCreatedByCurrentPerson && bankAccountInfo.routingNumber}
          readOnly={isDisabled}
          password
        />
        <NumberInput
          name="accountNumber"
          label={t('bank:fields.accountNumber.label')}
          className="mb-6"
          control={control}
          error={errors.accountNumber}
          type="int"
          maxLength={17}
          allowNegative={false}
          hideValue={!isCreatedByCurrentPerson && bankAccountInfo.accountNumber}
          readOnly={isDisabled}
          password
        />
        <Input
          name="achCompanyId"
          label={t('bank:fields.achCompanyId.label')}
          className="mb-10"
          type="password"
          register={register}
          error={errors.achCompanyId}
          hideValue={!isCreatedByCurrentPerson && bankAccountInfo.achCompanyId}
          readOnly={isDisabled}
          optional
        />
      </form>
    </div>
  );
};

export default BankAccountInfo;
